@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "ngx-toastr/toastr";
@import "../node_modules/ngx-spinner/animations/ball-scale-multiple.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  // width: 300px;
  width: 100%;
}

h3{
    color:#525668;
    text-align: center;
}


button {
  background-color: #9800f1;
  border: none;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}
button:hover {
  // background-color: #9800f1;
  background-color: #704aff
}

.card_form{
    width: 86%;
    margin-left: 7%;
    margin-top: 2%;
}

.form-group {
  margin-bottom: 15px;
}

.lable-css {
  color: #717177;
  font-weight: 450;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.back{
  margin: 2%;
}